import React from "react";
import { PixelsIntro } from "../../../assets/img/icons";
import ColumnText from "../../shared/columnText";

export default function Introduction() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="introduction">
      <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 p-grid-standard body-1 content">
        <div className="col-12">
          <p className="pb-30">
          Die Digitalinitiative <strong>NextGen4Bavaria des Bayerischen Staatsministeriums für Digitales</strong> ist ein 12-monatiges Programm für Unternehmensnachfolgerinnen und Unternehmensnachfolger und unterstützt diese dabei, Kompetenzen im Bereich Digitalisierung sowie Innovation auf- und auszubauen, um ihre (Familien-) Unternehmen zukunftsfähig zu machen sowie untereinander zu vernetzen.<br />
          </p>
        </div>
      </div>
    </section>
  );
}
