import React from "react";

export default function Claim() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="claim">
      <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 p-grid-standard body-1 content">
        <div className="col-12">
          <p className="pb-30 tc-blue">
            <strong>Digitale Geschäftsmodelle steigern Wachstum und Rentabilität.</strong>
            </p>  
          <p className="pb-30">
          Der Weg zu einer datengetriebenen Organisation ist jedoch nicht einfach und lässt sich oft nicht auf Knopfdruck umsetzen.
Gemeinsam mit Dir wollen wir geeignete Lösungen identifizieren und Dich zur treibenden Kraft im Digitalisierungsprozess Deines Unternehmens machen.
          </p>
        </div>
      </div>
    </section>
  );
}