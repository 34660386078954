import React from "react";
import Quote from "../../shared/quote";
import digitalMinister from "../../../assets/img/components/quote/digitalMinister.jpg";

export default function QuoteDigitalMinister() {
  return (
    <section id="quote-minister-gerlach">
      <Quote
        image={digitalMinister}
        imageAltText="Porträt von Dr. Fabian Mehring, Bayerischer Staatsminister für Digitales"
        who={
          <p>
            <strong>Dr. Fabian Mehring, Bayerischer Staatsminister für Digitales</strong>
          </p>
        }
        quote={
          <blockquote>
            "Mit unserer Initiative geben wir Rückenwind für Zukunftsmacher im Mittelstand. Konkret helfen wir jungen Unternehmern dabei, den digitalen Wandel in ihren Firmen aktiv zu gestalten. Mit NextGen4Bavaria machen wir greifbar, wie man die Chancen der Digitalisierung unternehmerisch anpacken kann und bereiten die zukünftigen Entscheiderinnen und Entscheider darauf vor, bayerische Traditionsunternehmen in eine erfolgreiche digitale Zukunft zu führen.“
          </blockquote>
        }
        side="right"
      />
    </section>
  );
}
