import React, { useState, useCallback } from "react";
import discovery from "../../../assets/img/components/infograph/home.jpg";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Zoom } from "../../../assets/img/icons";

export default function Infograph() {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const wrapperStyle = {
    width: "100%",
    height: "100%",
  };
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="infopragh">
      <div className="content py-standard">
        <div className="infograph">
          <div className="pxt-standard">
            <img
              className="infograph-icon lg:hidden xl:hidden"
              src={Zoom}
              onClick={handleImgLoad}
              alt="zoom"
              role="presentation"
            />
            <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
              <img style={wrapperStyle} src={discovery} alt="infografik" />
            </ControlledZoom>
          </div>
        </div>
      </div>
    </section>
  );
}
