import * as React from "react";
import DefautLayout from "../layouts/default";
import * as Home from "../components/screens/home";
import "../assets/sass/index.scss";

const IndexPage = () => {
  return (
    <DefautLayout title="Startseite">
      <div id="index">
        <Home.Header />
        <Home.Introduction />
        <Home.QuoteDigitalMinister />
        <Home.Claim />
        <Home.LocalHeaderIndex />
        <Home.Infograph />
        <Home.Teaser />
        <Home.QuotePresidentMinister />
      </div>
    </DefautLayout>
  );
};

export default IndexPage;
