import React from "react";
import Quote from "../../shared/quote";
import presidentMinister from "../../../assets/img/components/quote/czerny.webp";

export default function QuotePresidentMinister() {
  return (
    <section id="quote-minister-soder">
      <Quote
        image={presidentMinister}
        imageAltText="Portrait der Programmpatin Frau Steffi Czerny"
        who={
          <p>
            Als Programmpatin der Digitalinitiative NextGen4Bavaria begleitet <strong>Steffi Czerny</strong> die Unternehmensnachfolgerinnen und Unternehmensnachfolger auf ihrem Weg in die digitale Zukunft.
          </p>
        }
        quote={
          <blockquote>
            "Digitalisierung ist heutzutage so etwas wie Elektrizität – ohne das geht es nicht mehr. Junge Unternehmerinnen und Unternehmer fit für die Zukunft zu machen, ist ein hervorragender Gedanke. Sie in einem Programm zusammen zu bringen, wo sie Zukunft lernen, wo sie über die neuen Technologien lernen, wo sie sich einander kennenlernen, wo ihnen Mut gemacht wird, sich dem Thema Innovation zu stellen."
          </blockquote>
        }
        side="left"
      />
    </section>
  );
}
